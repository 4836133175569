import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React from 'react';
import { ApiContextProvider } from 'src/contexts/apiContext';

function ErrorComponent({ error }) {
	return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
	return <p>Authentication in progress...</p>;
}

// based on: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
const WithLogin = (Wrapped: React.ComponentType<{}>): JSX.Element => {

	const authRequest = {
		scopes: ["openid", "profile"]
	};

	return (
		<>
			<MsalAuthenticationTemplate
				interactionType={InteractionType.Redirect}
				authenticationRequest={authRequest}
				errorComponent={ErrorComponent}
				loadingComponent={LoadingComponent}
			>
				<ApiContextProvider>
					<Wrapped />
				</ApiContextProvider>
			</MsalAuthenticationTemplate>
		</>
	);
};

export default WithLogin;
