import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { FC, useState, createContext, useEffect, useContext } from 'react';
import { protectedResources } from 'src/authConfig';
import { apiDelete, apiGet, apiPost, apiPut } from 'src/fetch';
import { environment } from "src/components/common/env";
import { LinkModel } from 'src/models/linkModel';

export class Api {
  baseUrl = environment.apiBaseUrl;
  token: string;

  public requests = {
    links: {
      getAll: async () => {
        try {
          const url = protectedResources.api.links.getAll(this.baseUrl);
          const response = await apiGet(url, this.token);
          return (response);
        }
        catch (error) {

          console.error(error);
          throw (error);
        }
      },
      get: async (key: string) => {
        try {
          const url = protectedResources.api.links.get(this.baseUrl, key);
          console.log("calling:", url);
          const response = await apiGet(url, this.token);
          console.log("response from", url, ":", response);

          return (response);
        }
        catch (error) {
          console.error(error);
          throw (error);
        }
      },
      delete: async (key: string) => {
        try {
          const url = protectedResources.api.links.delete(this.baseUrl, key);
          console.log("calling:", url);
          const response = await apiDelete(url, this.token);
          console.log("response from", url, ":", response);

          return (response);
        }
        catch (error) {
          console.error(error);
          throw (error);
        }
      },
      put: async (link: LinkModel) => {
        try {
          const url = protectedResources.api.links.put(this.baseUrl, link.key);
          console.log("calling:", url);
          const response = await apiPut(url, link, this.token);
          console.log("response from", url, ":", response);

          return (response);
        }
        catch (error) {
          console.error(error);
          throw (error);
        }
      },
      post: async (link: LinkModel) => {
        try {
          const url = protectedResources.api.links.post(this.baseUrl, link.key);
          console.log("calling:", url);
          const response = await apiPost(url, link, this.token);
          console.log("response from", url, ":", response);

          return (response);
        }
        catch (error) {
          console.error(error);
          throw (error);
        }
      }
    },
    reports: {
      link: async (key: string) => {
        try {
          const url = protectedResources.api.reports.link(this.baseUrl, key);
          const response = await apiGet(url, this.token);
          return (response);
        }
        catch (error) {
          console.error(error);
          throw (error);
        }
      },
      user: async () => {
        try {
          const url = protectedResources.api.reports.user(this.baseUrl);
          const response = await apiGet(url, this.token);
          return (response);
        }
        catch (error) {
          console.error(error);
          throw (error);
        }
      }
    }
  };
}


export const ApiContext = createContext<Api>({} as Api);

export const useApi = () => useContext(ApiContext);

export const ApiContextProvider: FC = ({ children }) => {

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [api, setApi] = useState<Api | null>(null);

  useEffect(() => {

    if (account && inProgress === "none") {
      instance.acquireTokenSilent({
        scopes: protectedResources.api.scopes,
        account: account

      }).then((response) => {
        var newApi = api || new Api();
        newApi.token = response.accessToken;
        setApi(newApi);

      }).catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError) {
          if (account && inProgress === "none") {
            instance.acquireTokenPopup({
              scopes: protectedResources.apiLinks.scopes,
            }).then((response) => {

              var newApi = api || new Api();
              newApi.token = response.accessToken;
              setApi(newApi);

            }).catch(error => console.log(error));
          }
        }
      });
    }
  }, [account, inProgress, instance, api]);

  return (
    <ApiContext.Provider value={api}>
      {children}
    </ApiContext.Provider>
  );
};
