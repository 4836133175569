import { useMsal } from '@azure/msal-react';
import { FC, useState, createContext, useEffect } from 'react';
import { User } from 'src/models/user';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserContext = createContext<User>(
    {} as User
);

export const UserContextProvider: FC = ({ children }) => {

    const msal = useMsal();
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (msal && msal.accounts.length > 0) {
            setUser({
                id: msal.accounts[0].idTokenClaims['sub'],
                userName: msal.accounts[0].username,
                email: msal.accounts[0].username,
                name: msal.accounts[0].name
            })
        }
    }, [msal]);

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
};
