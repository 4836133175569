import { useRoutes } from 'react-router-dom';
import { routes } from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { MsalProvider } from '@azure/msal-react';
import { ConfirmationServiceProvider } from './contexts/confirmationService';
import { UserContextProvider } from './contexts/userContext';

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ msalInstance }) => {

  const content = useRoutes(routes);

  return (
    <ThemeProvider>
      <MsalProvider instance={msalInstance}>
        <UserContextProvider>
          <ConfirmationServiceProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              {content}
            </LocalizationProvider>
          </ConfirmationServiceProvider>
        </UserContextProvider>
      </MsalProvider>
    </ThemeProvider>
  );
}
export default App;
