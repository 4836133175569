import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import WithLogin from './components/auth/WithLogin';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Landing Page
const Overview = Loader(lazy(() => import('src/content/overview')));
const LinkBridge = Loader(lazy(() => import('src/content/links/linkBridge')));

// Links
const Links = Loader(lazy(() => import('src/content/links')));
const LinkDetails = Loader(lazy(() => import('src/content/links/linkDetails')));
const LinkEditor = Loader(lazy(() => import('src/content/links/linkEditor')));
const LinkCreator = Loader(lazy(() => import('src/content/links/linkCreator')));

// Status
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));

const SidebarLayoutWithLogin = (): JSX.Element => WithLogin(SidebarLayout);

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'overview',
        element: (
          <Navigate
            to="/"
            replace
          />
        )
      },
      {
        path: '/status',
        children: [
          {
            path: '',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          }
        ]
      },
      {
        path: '/:key',
        element: <LinkBridge />
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: '/links',
    element: (
      <SidebarLayoutWithLogin />
    ),
    children: [
      {
        path: '',
        element: <Links />
      },
      {
        path: ':key/details',
        element: <LinkDetails />
      },
      {
        path: ':key/edit',
        element: <LinkEditor />
      },
      {
        path: 'create',
        element: <LinkCreator />
      }
    ]
  }
];