export const environment = {
	apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
	AD_B2C_Tenant: process.env.REACT_APP_AD_B2C_TENANT || '',
	AD_B2C_ApplicationId: process.env.REACT_APP_AD_B2C_APPLICATION_ID || '',
	AD_B2C_AuthorityDomain: process.env.REACT_APP_AD_B2C_AUTHORITY_DOMAIN || '',
	AD_B2C_SignUpSignInPolicy: process.env.REACT_APP_AD_B2C_SIGN_UP_SIGN_IN_POLICY || '',
	AD_B2C_ForgotPasswordPolicy: process.env.REACT_APP_AD_B2C_FORGOT_PASSWORD_POLICY || '',
	AD_B2C_EditProfilePolicy: process.env.REACT_APP_AD_B2C_EDIT_PROFILE_POLICY || '',
	GA_MeasurementId: process.env.REACT_APP_GA_MEASUREMENT_ID || ''
};
